<template>
    <div class="info-about-card-container">
        <card-usp-box 
            v-if="$validateText(uspBoxDescription)"
            :usp-box-title="uspBoxTitle"
            :usp-box-description="uspBoxDescription"
        />
        <div v-if="$validateText(pros)">
            <span class="title">
                {{ $translate('pros', 'Fördelar') }}
            </span>
            <ul class="pros">
                <li 
                    v-for="(pro, index) in pros" 
                    :key="index"
                    class="rich-text"
                >
                    <i class="fa-solid fa-check-circle" />
                    <span>
                        {{ $prismic.asText([pro]) }}
                    </span>
                </li>
            </ul>
        </div>
        <div v-if="filteredInformation.length" >
            <span class="title">
                {{ $translate('card_application_information', 'Information') }}
            </span>
            <ul class="card-price-wrapper">
                <li 
                    v-for="(data, index) in filteredInformation" 
                    :key="index"
                >
                    <span class="card-price-header">{{ data.header }}</span>
                    <div class="card-price-value rich-text">
                        <span v-html="data.value" />
                        <tooltip v-if="data.showtooltip" :tooltip-text="data.tooltipText" />
                    </div>
                </li>
            </ul>
        </div>
        <span v-if="complianceExample" class="text-compliance">
            {{ complianceExample }}
        </span>
    </div>
</template>

<script>
import Tooltip from '@/components/features/Tooltip.vue';
import CardUspBox from '@/components/features/CardUspBox.vue';
export default {
    components: {
        CardUspBox,
        Tooltip
    },
    props: {
        uspBoxTitle: {
            type: String,
            required: true
        },
        uspBoxDescription: {
            type: Array,
            required: true
        },
        pros: {
            type: Array,
            required: true
        },

        // INFORMATION EXAMPLE:
        // [
        //     {
        //         header: this.$translate('annual_fee', 'Årsavgift'),
        //         value: this.model.yearlyCostHtml,
        //         showtooltip: this.model.hasFirstYearOffer,
        //         tooltipText: this.$translate('annual_fee_offer_tooltip', ''),
        //     },
        //     {
        //         header: this.$translate('max_credit', 'Högsta kredit'),
        //         value: this.model.getMaxCreditString(this.$translate('unlimited_max_credit', 'Obegränsad')),
        //         disabled: !this.model.isCredit
        //     },
        // ]
        information: {
            type: Array,
            required: false,
            default: () => []
        },
        complianceExample: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        filteredInformation() {
            return this.information
                .filter(data => !data.disabled);
        }
    },
};
</script>
<style lang="scss" scoped>
    .info-about-card-container::v-deep {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        @include font-size(14px, 8px);

        .title {
            display: block;
            font-weight: bold;
            border-bottom: solid 1px $purplebg;
            padding-bottom: 0.25rem;
            margin-bottom: 0.5rem;
        }
        
        .usp-box {
            flex-direction: column;
            width: fit-content;
            @include device(pad) {
                flex-direction: row;
                width: fit-content;
            }
            @include device(desktop) {
                flex-direction: column;
            }
            gap: 0.5rem;
            margin: 0;

            &__icon {
                display: none;
                @include device(pad) {
                    display: flex;
                    font-size: 1.5em;
                    width: 45px;
                    height: 45px;
                }
                @include device(desktop) {
                    display: none;
                }
            }

            &__content {
                padding: 0;
                span:last-child  {
                    @include font-size(12px, 8px);
                    color: $font;
                    opacity: 0.6;
                    font-weight: normal;
                }
                span {
                    @include font-size(13px, 8px)
                }
            }
        }

        .pros {
            margin-top: 0 !important;
            padding-top: 0.25rem !important;
            
            li p, li span {
                color: $font;
                opacity: 0.6;
                font-weight: normal;
            }
        }

        .card-price-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0.5rem;
            @include device(pad) {
                flex-direction: row;
                justify-content: start;
                gap: 2.5rem;
            }
            @include device(desktop) {
                flex-direction: column;
                justify-content: space-between;
                gap: 0.5rem;
            }

            li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 0.5rem;

                @include device(pad) {
                    flex-direction: column;
                    justify-content: start;
                }
                @include device(desktop) {
                    flex-direction: row;
                    justify-content: space-between;
                }

                .card-price-header {
                    color: $font;
                    opacity: 0.6;
                    font-weight: normal;
                }   
                .card-price-value {
                    font-weight: bold;
                }
                .standard-yearly-cost {
                    color: red;
                    margin-left: 5px;
                    padding: 0!important;
                    text-decoration: line-through;
                }
            }
        }
    }
</style>