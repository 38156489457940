export default {
    methods: {
        getSubHeader(label) {
            return {
                type: 'header',
                label: label
            };
        },
        getCreditAmountInput(min, max) {

            if (! min) {
                min = 5000;
            }

            if (! max) {
                max = 100000;
            }

            return {
                type: 'slider',
                label: 'Önskad kredit',
                min: min,
                max: max,
                validate: (value) => {
                    return value > 0;
                }
            };
        },
        getPersonalNumberInput() {
            return {
                type: 'text',
                label: 'Personnummer (12 siffror)',
                placeholder: '199001017033',
                errorText: 'Ange ett giltigt personnummer',
                onlyNumbers: true,

                validate: (value) => {
                    return value.length === 10 || value.length === 12;
                }
            };
        },
        getPhoneNumberInput() {
            return {
                type: 'text',
                label: this.$translate('card_application_phone_number', 'Telefonnummer'),
                placeholder: '07',
                errorText: this.$translate('card_application_phone_number_error', 'Ange ett giltigt telefonnummer'),
                onlyNumbers: true,

                validate: this.$validatePhoneNumber
            };
        },
        getEmailInput() {
            return {
                type: 'text',
                label: this.$translate('card_application_email', 'E-post'),
                placeholder: this.$translate('card_application_email_placeholder', 'din@epost.se'),
                errorText: this.$translate('card_application_email_error', 'Ange en giltig email'),
                onlyNumbers: false,
                
                validate: (value) => {
                    return this.$validateEmail(value);
                }
            };
        },
        getPepInput() {
            return {
                type: 'select',
                label: 'Är du en person i politiskt utsatt ställning eller känner du en person i politiskt utsatt ställning?',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Ja, jag är eller har varit PEP',
                        value: 1
                    },
                    {
                        name: 'Ja, jag har nära anknytning till en PEP',
                        value: 2
                    },
                    {
                        name: 'Nej',
                        value: 0
                    },
                ],

                validate: (value) => {
                    return typeof(value) === 'number';
                }
            };
        },
        getPepTitleInput() {
            return {
                type: 'select',
                label: 'Titel',
                errorText: 'Välj ett alternativ',
                options: this.getPepTitleOptions(),
                
                visibleIf: (inputs) => {
                    return inputs.isPep == 1 || inputs.isPep == 2;
                },

                validate: (value) => {
                    return this.$validateText(value);
                }
            };
        },
        getPepConnectionRelationInput() {
            return {
                type: 'select',
                label: 'Din relation till person i politiskt utsatt ställning',
                errorText: 'Välj ett alternativ',
                options: this.getPepRelationOptions(),
                
                visibleIf: (inputs) => {
                    return inputs.isPep == 2;
                },

                validate: (value) => {
                    return this.$validateText(value);
                }
            };
        },
        getPepConnectionPersonalNumberInput() {
            return {
                type: 'text',
                label: 'Personnummer (12 siffor)',
                placeholder: '',
                errorText: 'Ange ett giltigt personnummer',
                onlyNumbers: true,
                
                visibleIf: (inputs) => {
                    return inputs.isPep == 2;
                },


                validate: (value) => {
                    return value.length === 10 || value.length === 12;
                }
            };
        },
        getPepConnectionNameInput() {
            return {
                type: 'text',
                label: 'Namn',
                placeholder: 'Förnamn Efternamn',
                errorText: 'Ange ett giltig namn',
                onlyNumbers: false,
                
                visibleIf: (inputs) => {
                    return inputs.isPep == 2;
                },
                
                validate: (value) => {
                    return value && value.length > 5 && /\s/.test(value);
                }
            };
        },
        getPepConnectionCountryInput() {
            return {
                type: 'select',
                label: 'Välj landet där person i politiskt utsatt ställning är verksam',
                errorText: 'Välj ett alternativ',
                options: this.getCountryOptions(),
                
                visibleIf: (inputs) => {
                    return inputs.isPep == 2;
                },

                validate: (value) => {
                    return this.$validateText(value);
                }
            };
        },
        getCarOwnerInput() {
            return {
                type: 'select',
                label: 'Äger du ett fordon?',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Ja',
                        value: 1
                    },
                    {
                        name: 'Nej',
                        value: 2
                    },
                ],

                validate: (value) => {
                    return value >= 1 && value <= 2;
                }
            };
        },
        getEmploymentStatusInput() {
            return {
                type: 'select',
                label: 'Anställningsstatus',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Tillsvidareanställd',
                        value: 1
                    },
                    {
                        name: 'Student',
                        value: 2,
                    },
                    {
                        name: 'Pensionär',
                        value: 3
                    },
                    {
                        name: 'Sjukpensionär',
                        value: 4
                    },
                    {
                        name: 'Arbetslös',
                        value: 5
                    },
                    {
                        name: 'Vikarie',
                        value: 6
                    },
                    {
                        name: 'Egenföretagare',
                        value: 7
                    }
                ],
                
                validate: (value) => {
                    return value > 0;
                }
            };
        },
        getMonthlyIncomeInput() {
            return {
                type: 'text',
                label: 'Månadsinkomst (före skatt)',
                placeholder: '35000',
                errorText: 'Ange månadsinkomst',
                onlyNumbers: true,
                validate: (value) => {
                    return value > 0;
                }
            };
        },
        getEmployerInput() {
            return {
                type: 'text',
                label: 'Arbetsgivare',
                placeholder: 'Företag AB',
                errorText: 'Ange arbetsgivare',
                onlyNumbers: false,
                visibleIf: (inputs) => {
                    return [1, 6, 7].includes(inputs.employmentStatus);
                },
                validate: (value) => {
                    return value.length > 0;
                }
            };
        },
        getHousingInput() {
            return {
                type: 'select',
                label: 'Boendeform',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Villa',
                        value: 1
                    },
                    {
                        name: 'Bostadsrätt',
                        value: 2
                    },
                    {
                        name: 'Hyresrätt',
                        value: 3
                    }
                ],
                validate: (value) => {
                    return value > 0;
                }
            };
        },
        getMonthlySpendInput() {
            return {
                type: 'select',
                label: 'Hur mycket förväntar du att handla för i genomsnitt per månad?',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Mindre än 10 000 kr',
                        value: 1
                    },
                    {
                        name: '10 000 kr - 30 000 kr',
                        value: 2,
                    },
                    {
                        name: 'Mer än 30 000 kr',
                        value: 3
                    }
                ],
                validate: (value) => {
                    return value > 0;
                }
            };
        },
        getShoppingAbroadInput() {
            return {
                type: 'select',
                label: 'Förväntar du att använda kortet för köp utomlands?',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Ja',
                        value: 2
                    },
                    {
                        name: 'Nej',
                        value: 1,
                    }
                ],
                validate: (value) => {
                    return value === 1 || value === 2;
                }
            };
        },
        getEmployerPhoneInput() {
            return {
                type: 'text',
                label: 'Telefonnummer till arbetsgivare',
                placeholder: '',
                errorText: 'Ange arbetsgivare',
                onlyNumbers: false,

                visibleIf: (inputs) => {
                    return [1, 6, 7].includes(inputs.employmentStatus);
                },
                validate: (value) => {
                    return value.length > 5;
                }
            };
        },
        getEmploymentFromInput() {
            return {
                type: 'text',
                label: 'Anställd sedan (ÅÅÅÅ-MM-DD)',
                allowDash: true,
                errorText: 'Ange korrekt format: ÅÅÅÅ-MM-DD',
                onlyNumbers: true,
                visibleIf: (inputs) => {
                    return [1, 6, 7].includes(inputs.employmentStatus);
                },
                validate: (value) => {
                    const regex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
                    return value.length === 10 &&
                        regex.test(value);
                }
            };
        },
        getEmploymentToInput() {
            return {
                type: 'text',
                label: 'Anställd till och med (ÅÅÅÅ-MM-DD)',
                allowDash: true,
                errorText: 'Ange korrekt format: ÅÅÅÅ-MM-DD',
                onlyNumbers: true,
                visibleIf: (inputs) => {
                    return inputs.employmentStatus === 6;
                },
                validate: (value) => {
                    const regex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
                    return value.length === 10 && regex.test(value) && new Date(value) > new Date();
                }
            };
        },
        getMonthlyCreditCost() {
            return {
                type: 'text',
                label: 'Månatliga kreditkostnader (utöver bolån)',
                tooltipText: 'Din del av månatliga kreditkostnader (bolån exkluderat). Dessa kan inkludera ränta, avgifter och andra avgifter.',
                errorText: 'Ange dina månatliga kreditkostnader',
                placeholder: '500',
                onlyNumbers: true,
                validate: (value) => {
                    return value.length > 0;
                }
            };
        },
        getMonthlyLivingCost() {
            return {
                type: 'text',
                label: 'Månatliga levnadskostnader',
                tooltipText: 'Din del av månatliga levnadskostnader. Dessa kan inkludera mat, kläder, transport, nöjen och andra utgifter.',
                errorText: 'Ange dina månatliga levnadskostnader',
                placeholder: '5000',
                default: 0,
                onlyNumbers: true,
                validate: (value) => {
                    return value.length > 0;
                }
            };
        },
        getTotalCreditAmount() {
            return {
                type: 'text',
                label: 'Totala krediter (utöver bolån)',
                tooltipText: 'Den totala summan av dina krediter (förutom bolån), vilket inkluderar till exempel kreditkort, privatlån och andra lån. Ange bara din del av krediten. Om du inte har några krediter, ange 0.',
                errorText: 'Ange den totala summan för alla dina krediter',
                placeholder: '0',
                onlyNumbers: true,
                validate: (value) => {
                    return value.length > 0;
                }
            };
        },
        getMonthlyMortgageCost() {
            return {
                type: 'text',
                label: 'Månatliga bolånekostnader (0 om inget bolån)',
                tooltipText: 'Din del av kostnader relaterade till bolån. Dessa kan inkludera ränta, amortering och andra avgifter. Om du inte har något bolån, ange 0.',
                errorText: 'Ange dina månatliga bolånekostnader',
                placeholder: '0',
                onlyNumbers: true,
                validate: (value) => {
                    return value.length > 0;
                }
            };
        },
        getHouseholdNumberChildren() {
            return {
                type: 'text',
                label: 'Antal barn under 18 år i hushållet',
                errorText: 'Ange ett giltigt värde',
                onlyNumbers: true,
                validate: (value) => {
                    return value.length > 0 && Number(value) <= 10;
                }
            };
        },
        getHouseholdNumberAdults() {
            return {
                type: 'text',
                label: 'Antal vuxna i hushållet',
                errorText: 'Ange ett giltigt värde',
                onlyNumbers: true,
                validate: (value) => {
                    return value.length > 0 && Number(value) >= 1 && Number(value) <= 10;
                }
            };
        },
        getHousingCost() {
            return {
                type: 'text',
                label: 'Boendekostnad per månad',
                tooltipText: 'Din del av boendekostnaden per månad. Beloppet ska inkludera hyra, försäkring, vatten och värme.',
                errorText: 'Ange ett giltigt värde',
                placeholder: '3000',
                default: 0,
                onlyNumbers: true,
                validate: (value) => {
                    return value.length > 0;
                }
            };
        },
        getCivilStatus() {
            return {
                type: 'select',
                label: 'Vad är ditt civilstånd?',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Ensamstående',
                        value: 1
                    },
                    {
                        name: 'Gift',
                        value: 2
                    },
                    {
                        name: 'Sambo',
                        value: 3
                    },
                    {
                        name: 'Änka',
                        value: 4
                    },
                    {
                        name: 'Skild',
                        value: 5
                    },
                ],

                validate: (value) => {
                    return value >= 1 && value <= 5;
                }
            };
        },
        getPepRelationOptions() {
            return [
                {
                    name: 'Make/maka, registrerad partner eller sambo',
                    value: 'Make/maka, registrerad partner eller sambo',
                },
                {
                    name: 'Barn',
                    value: 'Barn ',
                },
                {
                    name: 'Barns make/maka, registrerad partner eller sambo',
                    value: 'Barns make/maka, registrerad partner eller sambo',
                },
                {
                    name: 'Förälder',
                    value: 'Förälder',
                },
                {
                    name: 'Medarbetare',
                    value: 'Medarbetare',
                },
                {
                    name: 'Annan nära affärsförbindelse',
                    value: 'Annan nära affärsförbindelse',
                }
            ]
        },
        getPepTitleOptions() {
            return [
                {
                    name: 'Statschef',
                    value: 'Statschef'
                },
                {
                    name: 'Regeringschef',
                    value: 'Regeringschef'
                },
                {
                    name: 'Minister',
                    value: 'Minister'
                },
                {
                    name: 'Vice eller biträdande minister',
                    value: 'Vice eller biträdande minister'
                },
                {
                    name: 'Parlamentsledamot',
                    value: 'Parlamentsledamot'
                },
                {
                    name: 'Domare i högsta domstolen',
                    value: 'Domare i högsta domstolen'
                },
                {
                    name: 'Högre tjänsteman vid revisionsmyndighet',
                    value: 'Högre tjänsteman vid revisionsmyndighet'
                },
                {
                    name: 'Ledamot av centralbanks styrande organ',
                    value: 'Ledamot av centralbanks styrande organ'
                },
                {
                    name: 'Ambassadör',
                    value: 'Ambassadör'
                },
                {
                    name: 'Högre officer i Försvarsmakten',
                    value: 'Högre officer i Försvarsmakten'
                },
                {
                    name: 'Ledamot av politiskt partis styrelse',
                    value: 'Ledamot av politiskt partis styrelse'
                },
                {
                    name: 'VD eller styrelseledamot i statsägda företag',
                    value: 'VD eller styrelseledamot i statsägda företag'
                },
                {
                    name: 'Person i ledningen i en internationell organisation',
                    value: 'Person i ledningen i en internationell organisation'
                },
                {
                    name: 'Riksdagsledamot',
                    value: 'Riksdagsledamot'
                },
                {
                    name: 'Annat',
                    value: 'Annat'
                }
            ];
        },
        getCountryOptions() {
            return [
                {
                    name: "Albanien",
                    value: "Albanien"
                },
                {
                    name: "Andorra",
                    value: "Andorra"
                },
                {
                    name: "Anguilla",
                    value: "Anguilla"
                },
                {
                    name: "Antigua och Barbuda",
                    value: "Antigua och Barbuda"
                },
                {
                    name: "Argentina",
                    value: "Argentina"
                },
                {
                    name: "Aruba",
                    value: "Aruba"
                },
                {
                    name: "Australien",
                    value: "Australien"
                },
                {
                    name: "Barbados",
                    value: "Barbados"
                },
                {
                    name: "Belgien",
                    value: "Belgien"
                },
                {
                    name: "Belize",
                    value: "Belize"
                },
                {
                    name: "Bermuda",
                    value: "Bermuda"
                },
                {
                    name: "Brasilien",
                    value: "Brasilien"
                },
                {
                    name: "Brittiska Jungfru\u00f6arna",
                    value: "Brittiska Jungfru\u00f6arna"
                },
                {
                    name: "Bulgarien",
                    value: "Bulgarien"
                },
                {
                    name: "Cayman\u00f6arna",
                    value: "Cayman\u00f6arna"
                },
                {
                    name: "Chile",
                    value: "Chile"
                },
                {
                    name: "Colombia",
                    value: "Colombia"
                },
                {
                    name: "Cook\u00f6arna",
                    value: "Cook\u00f6arna"
                },
                {
                    name: "Costa Rica",
                    value: "Costa Rica"
                },
                {
                    name: "Curacao",
                    value: "Curacao"
                },
                {
                    name: "Cypern",
                    value: "Cypern"
                },
                {
                    name: "Danmark",
                    value: "Danmark"
                },
                {
                    name: "Estland",
                    value: "Estland"
                },
                {
                    name: "Finland",
                    value: "Finland"
                },
                {
                    name: "Frankrike",
                    value: "Frankrike"
                },
                {
                    name: "F\u00e4r\u00f6arna",
                    value: "F\u00e4r\u00f6arna"
                },
                {
                    name: "F\u00f6renade kungariket",
                    value: "F\u00f6renade kungariket"
                },
                {
                    name: "Ghana",
                    value: "Ghana"
                },
                {
                    name: "Gibraltar",
                    value: "Gibraltar"
                },
                {
                    name: "Grekland",
                    value: "Grekland"
                },
                {
                    name: "Grenada",
                    value: "Grenada"
                },
                {
                    name: "Gr\u00f6nland",
                    value: "Gr\u00f6nland"
                },
                {
                    name: "Guernsey",
                    value: "Guernsey"
                },
                {
                    name: "Indien",
                    value: "Indien"
                },
                {
                    name: "Indonesien",
                    value: "Indonesien"
                },
                {
                    name: "Irland",
                    value: "Irland"
                },
                {
                    name: "Island",
                    value: "Island"
                },
                {
                    name: "Israel",
                    value: "Israel"
                },
                {
                    name: "Isle of Man",
                    value: "Isle of Man"
                },
                {
                    name: "Italien",
                    value: "Italien"
                },
                {
                    name: "Japan",
                    value: "Japan"
                },
                {
                    name: "Jersey",
                    value: "Jersey"
                },
                {
                    name: "Kanada",
                    value: "Kanada"
                },
                {
                    name: "Kina",
                    value: "Kina"
                },
                {
                    name: "Kroatien",
                    value: "Kroatien"
                },
                {
                    name: "Kuwait",
                    value: "Kuwait"
                },
                {
                    name: "Lettland",
                    value: "Lettland"
                },
                {
                    name: "Liechtenstein",
                    value: "Liechtenstein"
                },
                {
                    name: "Litauen",
                    value: "Litauen"
                },
                {
                    name: "Luxemburg",
                    value: "Luxemburg"
                },
                {
                    name: "Malaysia",
                    value: "Malaysia"
                },
                {
                    name: "Malta",
                    value: "Malta"
                },
                {
                    name: "Marshall\u00f6arna",
                    value: "Marshall\u00f6arna"
                },
                {
                    name: "Mauritius",
                    value: "Mauritius"
                },
                {
                    name: "Mexiko",
                    value: "Mexiko"
                },
                {
                    name: "Monaco",
                    value: "Monaco"
                },
                {
                    name: "Montserrat",
                    value: "Montserrat"
                },
                {
                    name: "Nauru",
                    value: "Nauru"
                },
                {
                    name: "Nederl\u00e4nderna",
                    value: "Nederl\u00e4nderna"
                },
                {
                    name: "Niue",
                    value: "Niue"
                },
                {
                    name: "Norge",
                    value: "Norge"
                },
                {
                    name: "Nya Zeeland",
                    value: "Nya Zeeland"
                },
                {
                    name: "Polen",
                    value: "Polen"
                },
                {
                    name: "Portugal",
                    value: "Portugal"
                },
                {
                    name: "Rum\u00e4nien",
                    value: "Rum\u00e4nien"
                },
                {
                    name: "Ryssland",
                    value: "Ryssland"
                },
                {
                    name: "Saint Lucia",
                    value: "Saint Lucia"
                },
                {
                    name: "Saint Kitts och Nevis",
                    value: "Saint Kitts och Nevis"
                },
                {
                    name: "Saint Vincent och Grenadinerna",
                    value: "Saint Vincent och Grenadinerna"
                },
                {
                    name: "Samoa",
                    value: "Samoa"
                },
                {
                    name: "San Marino",
                    value: "San Marino"
                },
                {
                    name: "Saudiarabien",
                    value: "Saudiarabien"
                },
                {
                    name: "Schweiz",
                    value: "Schweiz"
                },
                {
                    name: "Seychellerna",
                    value: "Seychellerna"
                },
                {
                    name: "Sint Maarten",
                    value: "Sint Maarten"
                },
                {
                    name: "Slovakien",
                    value: "Slovakien"
                },
                {
                    name: "Slovenien",
                    value: "Slovenien"
                },
                {
                    name: "Spanien",
                    value: "Spanien"
                },
                {
                    name: "Sydafrika",
                    value: "Sydafrika"
                },
                {
                    name: "Sydkorea",
                    value: "Sydkorea"
                },
                {
                    name: "Taiwan",
                    value: "Taiwan"
                },
                {
                    name: "Thailand",
                    value: "Thailand"
                },
                {
                    name: "Tjeckien",
                    value: "Tjeckien"
                },
                {
                    name: "Turks- och Caicos\u00f6arna",
                    value: "Turks- och Caicos\u00f6arna"
                },
                {
                    name: "Tyskland",
                    value: "Tyskland"
                },
                {
                    name: "Ungern",
                    value: "Ungern"
                },
                {
                    name: "Uruguay",
                    value: "Uruguay"
                },
                {
                    name: "\u00d6sterrike",
                    value: "\u00d6sterrike"
                }
            ];
        },
        getUseCaseInput() {
            return {
                type: 'select',
                label: 'Vad ska du huvudsakligen använda kortet till?',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Daglig handel (mat, kläder m.m.)',
                        value: 1
                    },
                    {
                        name: 'Resor/Semester',
                        value: 2
                    },
                    {
                        name: 'Kontantuttag',
                        value: 3
                    },
                    {
                        name: 'Internethandel',
                        value: 4
                    },
                    {
                        name: 'Nöje (bio, restaurang m.m.)',
                        value: 5
                    },
                ],
                validate: (value) => {
                    return value > 0;
                }
            };
        },
        getIncomeSource() {
            return {
                type: 'select',
                label: 'Vad är din huvudsakliga inkomstkälla?',
                errorText: 'Välj ett alternativ',
                options: [
                    {
                        name: 'Lön',
                        value: 1
                    },
                    {
                        name: 'Pension',
                        value: 2
                    },
                    {
                        name: 'Investering',
                        value: 3
                    },
                    {
                        name: 'Studiemedel',
                        value: 4
                    },
                    {
                        name: 'Bidrag',
                        value: 5
                    },
                    {
                        name: 'A-kassa',
                        value: 6
                    },
                    {
                        name: 'Sparade pengar',
                        value: 7
                    },
                    {
                        name: 'Lån',
                        value: 8
                    },
                    {
                        name: 'Annan',
                        value: 9
                    },
                ],

                validate: (value) => {
                    return value >= 1 && value <= 9;
                }
            };
        },
    }
};