// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-1b883d42]{font-family:\"Open Sans\"}.font-header[data-v-1b883d42]{font-family:\"Hero\"}.information[data-v-1b883d42]{border:0;box-sizing:border-box;border-top:1px solid rgba(241,214,245,.7);margin-top:20px;padding-top:40px}.rich-text ul[data-v-1b883d42]{grid-gap:0;gap:0}h5[data-v-1b883d42]{margin-top:30px}.cost[data-v-1b883d42]{margin-top:20px;padding:20px;display:grid;background:#f9fafc;text-align:center}.cost .amount[data-v-1b883d42]{color:#01685e;font-size:20px;font-weight:700}.cost .header[data-v-1b883d42]{font-size:14px;margin-top:10px}.requirements[data-v-1b883d42]{background:#f9fafc;padding:10px 20px;margin-top:20px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
