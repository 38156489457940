<template>
    <Section width="md">
        <div 
            v-if="errorFromApi !== null" 
            class="income-error"
            style="margin-bottom: 10px;"
        >
            <ul v-if="Array.isArray(errorFromApi)">
                <li
                    v-for="(error, index) in errorFromApi" :key="index" style="margin-bottom: 3px;"
                >
                    {{ error }}
                </li>
            </ul>
            <ul v-else>
                <li style="margin-bottom: 3px;">
                    {{ errorFromApi }}
                </li>
            </ul>
        </div>

        <div v-if="waitingForResponse" class="form">
            <div class="form-container">
                <img 
                    src="/loading.gif" 
                    class="center load" 
                    alt="Loading"
                >
            </div>
        </div>
        <insurance-form 
            v-else-if="showInsuranceForm"
            @addInsurance="addInsuranceAddon"
            @close="closeInsuranceFormAndSubmit"
        />
        <div 
            v-else
            class="main-container"
        >
            <form 
                class="form-container"
            >
                <div class="form-container__header">
                    <slot name="header" />
                </div>
                <template v-for="(input, key) in inputs">
                    <slider-input
                        v-if="input.type && input.type === 'slider' && (!input.hasOwnProperty('visibleIf') || input.visibleIf(values))"
                        :key="key"
                        :form-key="key"
                        :label="input.label"
                        :min="input.min"
                        :max="input.max"
                        @set="setInput"
                    />
                    <text-input 
                        v-else-if="input.type && input.type === 'text' && (!input.hasOwnProperty('visibleIf') || input.visibleIf(values))"
                        :key="key"
                        :form-key="key"
                        :label="input.label"
                        :placeholder="input.placeholder"
                        :tooltip-text="input.tooltipText"
                        :allow-only-numbers="input.onlyNumbers"
                        :allow-dash="input.allowDash || false"
                        :error="errors[key]"
                        :error-text="input.errorText"
                        @set="setInput"
                    />
                    <select-input
                        v-else-if="input.type && input.type === 'select' && (!input.hasOwnProperty('visibleIf') || input.visibleIf(values))"
                        :key="key"
                        :form-key="key"
                        :label="input.label"
                        :error="errors[key]"
                        :error-text="input.errorText"
                        :options="input.options"
                        @set="setInput"
                    />
                    <h4 v-else-if="input.type && input.type == 'header'" :key="input.label">
                        {{ input.label }}
                    </h4>
                </template>

                <div class="apply-btn">
                    <Button
                        :text="waitingForResponse ? 'Skickar...' : 'Ansök'"
                        size="lg"
                        background="orange"
                        @click="submitOrCheckForInsuranceAddon"
                    />
                </div>

                <slot name="terms" />
            </form>
            <div class="sidebar">
                <slot name="sidebar" />
            </div>
        </div>
        <CreditWarning v-if="$isSweden() && ! waitingForResponse" />
    </Section>
</template>

<script>

import Inputs from '@/mixins/inputs.js';
import TextInput from '@/components/slices/apply-for-cards/forms/form-components/TextInput.vue';
import SelectInput from '@/components/slices/apply-for-cards/forms/form-components/SelectInput.vue';
import SliderInput from '@/components/slices/apply-for-cards/forms/form-components/SliderInput.vue';
import InsuranceForm from '@/components/InsuranceForm.vue';
import CreditWarning from '@/components/CreditWarning.vue';

export default {
    mixins: [
        Inputs
    ],
    components: {
        TextInput,
        SelectInput,
        SliderInput,
        InsuranceForm,
        CreditWarning,
    },
    props: {
        offerId: {
            type: String,
            default: null
        },
        inputs: {
            type: Object,
            required: true
        },
        defaults: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        const values = {};
        const errors = {};

        Object.keys(this.inputs).forEach(key => {
            values[key] = '';
            errors[key] = false;
        });

        return {
            values: values,
            errors: errors,
            waitingForResponse: false,
            errorFromApi: null,
            payload: null,

            insuranceAddOn: null,
            insuranceAddOnChecked: false,
            showInsuranceForm: false,
        };
    },
    computed: {
        isAuction() {
            return this.offerId === null;
        }
    },
    methods: {
        setInput(key, value) {
            this.values[key] = value;
            
            if (value) {
                this.errors[key] = !this.inputs[key].validate(this.values[key]);
            }
        },
        submitOrCheckForInsuranceAddon() {
            if (this.insuranceAddOnChecked) {
                this.submit();
            }
            else {
                this.checkForInsuranceAddon();
            }
        },
        addInsuranceAddon(addOn) {
            this.insuranceAddOn = addOn;
            this.closeInsuranceFormAndSubmit();
        },
        closeInsuranceFormAndSubmit() {
            window.scrollTo(0, 0);
            this.showInsuranceForm = false;
            this.insuranceAddOnChecked = true;
            this.submit();
        },
        checkForInsuranceAddon() {
            if (!this.validate()) {
                window.scrollTo(0, 0);
                return;
            }

            // Disable insurance flow for now
            this.submit();

            return;

            /*if (! this.document.data.enable_insurance_addon) {
                this.submit();
                return;
            }*/

            // show insurance form, and submit later
            window.scrollTo(0, 0);
            this.showInsuranceForm = true;
        },
        validate() {
            Object.entries(this.inputs).forEach(([key, input]) => {

                if (input.type === 'header') {
                    return true;
                }

                // eslint-disable-next-line no-prototype-builtins
                if (input.hasOwnProperty('visibleIf') && !input.visibleIf(this.values)) {
                    return;
                }
                
                this.errors[key] = !input.validate(this.values[key]);
            });

            // copy error object so we dont filter out errors on 
            // original object
            const copy = Object.assign({}, this.errors);
            return Object.values(copy).filter(e => e).length === 0;
            
        },
        getPersonalNumber() {
            const personalNumber = this.values.personalNumber;
            if (personalNumber.length !== 12) {
                if (personalNumber.startsWith('0') || personalNumber.startsWith('1')) {
                    return `20${personalNumber}`;
                }
                
                return `19${personalNumber}`;
            }
            
            return personalNumber;
            
        },
        submit() { 

            if (this.waitingForResponse) {
                return;
            }

            if (!this.validate()) {
                window.scrollTo(0, 0);
                return;
            }

            this.waitingForResponse = true;

            let pepConnectionObject = false;
            if (this.values.isPep == 2 && this.values.pepConnectionPersonalNumber !== undefined) {
                pepConnectionObject = {
                    title: this.values.pepTitle,
                    personal_number: this.values.pepConnectionPersonalNumber,
                    name: this.values.pepConnectionName,
                    relation: this.values.pepConnectionRelation,
                    country: this.values.pepConnectionCountry,
                };
            }

            let applicantPepTitle = null;
            if (this.values.isPep == 1 && this.values.pepTitle !== undefined) {
                applicantPepTitle = this.values.pepTitle;
            }

            const offerPayload = {
                'credit_amount': this.values.credit || this.defaults.credit,
            };

            if (this.isAuction) {
                offerPayload.type = 'credit_card';
            }
            else {
                offerPayload.id = this.offerId;
            }

            const payload = {
                'source_id': 2,
                'offer': offerPayload,
                'applicant': {
                    'personal_number': this.getPersonalNumber(),
                    'phone_number': this.values.phoneNumber,
                    'email': this.values.email,
                    'pep': {
                        'is_pep': this.values.isPep == 1,
                        'title': applicantPepTitle,
                        'connection': pepConnectionObject
                    },
                },
                'questionnaire': {
                    'applicant': {},
                    'general': {}
                }
            };            

            if (this.insuranceAddOn) {
                payload.addons = [
                    this.insuranceAddOn
                ];
            }
            
            Object.entries({
                'employer': 'employer',
                'employmentStatus': 'employment_status',
                'employerPhone': 'employer_phone',
                'employmentFrom': 'employment_from',
                'employmentTo': 'employment_to',
                'monthlyIncome': 'monthly_income',
                'housing': 'housing',
                'monthlyCreditCost': 'monthly_credit_cost',
                'monthlyLivingCost': 'monthly_living_cost',
                'householdNumberChildren': 'household_number_children',
                'householdNumberAdults': 'household_number_adults',
                'existingLoanCost': 'existing_loan_cost',
                'housingCost': 'housing_cost',
                'civilStatus': 'civil_status',
                'carOwner': 'car_owner',
                'totalCreditAmount': 'total_credit_amount',
                'monthlyMortgageCost': 'monthly_mortgage_cost',
                'incomeSource': 'income_source',

            }).forEach(([key, apiKey]) => {
                if (this.inputs[key]) {
                    payload.questionnaire.applicant[apiKey] = this.values[key];
                }
            });

            Object.entries({
                'monthlySpend': 'credit_card_monthly_spend',
                'shoppingAbroad': 'credit_card_shopping_abroad',
                'useCase': 'credit_card_use_case',
            }).forEach(([key, apiKey]) => {
                if (this.inputs[key]) {
                    payload.questionnaire.general[apiKey] = this.values[key];
                }
            });

            payload.tracking = this.$tracking.getTrackingObject();

            this.$axios.post(this.isAuction ? '/auctions' : '/applications', payload)
                .then((response) => {
                    this.$event.applicationSubmitted(this.offerId);
                    this.$redirectFromApplicationResponse(response.data);
                })
                .catch((error) => {

                    window.scrollTo(0, 0);
                    if (error.response) {
                        this.errorFromApi = error.response.data.error;
                    }
                    else if (error.request) {
                        this.errorFromApi = ['Något gick fel: ' + error.message];
                    }
                    else {
                        this.errorFromApi = ['Något gick fel. Kontakta vår kundtjänst.'];
                    }
                })
                .then(() => {
                    this.waitingForResponse = false;
                });
        },
        
        
    }
}
</script>

<style lang="scss" scoped>
    section::v-deep {

        @include device(desktop) {
            background: $graybg;
        }
        
        margin-top: 50px;
        @include device(desktop) {
        }

        h1 {
            @include font-size(24px, 10px);
            margin-bottom: 5px;

            @include device(desktop) {
                @include font-size(30px, 10px);
            }
        }

        h4 {
            margin-top: 20px;
        }

        .income-error {
            color: darkred;
            background: rgba(179, 0, 0, .15);
            padding: 15px;
            border: 1px solid darkred;
            border-radius: 2px;
        }

        .main-container {
            display: grid;

            @include spacing(padding,0);
            @include device(desktop) {
                box-shadow: $bxs;
                @include border-radius(10px);
                @include border;
                background: $white;

                grid-template-columns: 2fr 1fr;
                gap: 50px;
                @include spacing(padding, 6);
            }


            ul.pros {
                margin-top: 20px;
                @include grid(1,15px);
                li {
                    @include flex(start,start);
                    line-height: 25px;
                    span {
                        margin-left: 10px;
                        margin-top: -5px;
                        flex: 1;
                    }
                }
            }

            .sidebar {
                display: none;
                @include device(desktop) {
                    display: block;
                }

                .sidebar-image {
                    text-align: center;
                    padding-bottom: 1rem;
                }
            }
        }

        form, .form {

            .form-container__header {
                margin-bottom: 0.75rem; 
                padding-bottom: 0.75rem;
                @include border(bottom);

                .form-container-expander {
                    display: block;
                    @include device(desktop) {
                        display: none;
                    }
                    .expand-row__button {
                        padding-bottom: 0;
                        justify-content: flex-start;
                        gap: 0.5rem;
                    }

                    .info-about-card-container {
                        margin-top: 0.5rem;
                    }
                }
            }

            label,
            .label {
                opacity: .6;
            }

            .form-inputs {
                @include grid(1,30px);
            }

            .apply-btn {
                @include border(top);
                margin-top: 10px;
                padding-top: 30px;
                @include flex;
                width: 100%;

                .btn {
                    max-width: 400px;
                    flex: 1;
                }
            }

            .terms {
                max-width: 400px;
                font-size: 14px;
                line-height: 20px;
                margin-left: auto; margin-right: auto;
                a {
                    color: $green;
                    &:hover { text-decoration: underline; }
                }
            }
        }

        .insurance {
            

            @include device(desktop) {
                box-shadow: $bxs;
                @include border-radius(20px);
                @include border;
                background: $white;
                @include spacing(padding,6);
            }

            .buttons-1 {
                @include grid(1,15px);
                margin-top: 30px;
            }
            .buttons-2 {
                @include grid(1,15px);
                margin-top: 30px;

                @media screen and (min-width: 768px) {
                    @include grid(2,15px);
                }
            }
            .buttons-3 {
                @include grid(1,15px);
                margin-top: 30px;

                @media screen and (min-width: 768px) {
                    @include grid(3,15px);
                }
            }
        }
    }

    .credit-warning {

        max-width: 400px;
        margin: 40px auto;

        @include device(desktop) {
            max-width: 100%;
            margin: 30px auto;
        }

    }
</style>
