<template>
    <div class="insurance">
        <div class="rich-text">
            <h3>Kortio Trygghetsförsäkring</h3>
            <p>Vill du komplettera din ansökan med en trygghetsförsäkring?</p>
            <p>Med Kortios Trygghetsförsäkring får du ett extra stöd vid eventuell arbetslöshet eller sjukskrivning. Få upp till 7 000 kr / mån för dina fasta kostnader som boendekostnader och räntor.</p>
            <div class="buttons-2">
                <Button
                    text="Ja, läs mer"
                    size="md"
                    :background="readMore ? 'green' : 'green-border'"
                    @click="readMore = !readMore"
                />
                <Button
                    text="Nej, gå vidare utan försäkring"
                    size="md"
                    background="green-border"
                    @click="close"
                />
            </div>
            <div v-if="readMore" class="information rich-text">
                <h4>Få upp till 7 000 kr/mån vid sjukskrivning och 3 500 kr/mån vid arbetslöshet</h4>
                <p>Kortio trygghetsförsäkring är en försäkring som hjälper dig.</p>
                <ul>
                    <li>Skattefritt och utan avdrag</li>
                    <li>Oberoende av din lön</li>
                    <li>Ökad ekonomisk trygghet vid arbetslöshet eller arbetsoförmåga</li>
                    <li>Ingen bindningstid, säg upp försäkringen när du vill</li>
                    <li>Ingen självrisk</li>
                    <li>14 dagar ångerrätt</li>
                </ul>
                <p>Försäkringen avser att täcka dina månatliga kostnader, men du avgör själv hur du använder dina försäkringspengar.</p>
                <p>Din försäkring gäller oavsett om du blir beviljad eller nekad ditt kreditkort.</p>

                <h5>Vad vill du ha för försäkringsbelopp?</h5>
                <div class="buttons-3">
                    <Button
                        v-for="option in options"
                        :key="'button' + option.key"
                        :text="option.healthPayout"
                        size="sm"
                        :background="insurance.key === option.key ? 'green' : 'green-border'"
                        @click="insurance = option;"
                    />
                </div>
                <div class="cost">
                    <span class="amount">{{ insurance.cost }}</span>
                    <span class="header">kostnad</span>
                </div>
                <div class="buttons-1">
                    <Button
                        :text="'Lägg till försäkring'"
                        size="lg"
                        background="purple"
                        @click="addInsuranceAndClose"
                    />
                </div>
                <h5>Din valda försäkring</h5>
                <div class="requirements">
                    <ul>
                        <li>Ersättning vid sjukskrivning: <strong>{{ insurance.healthPayout }}</strong></li>
                        <li>Ersättning vid arbetslöshet: <strong>{{ insurance.unemployedPayout }}</strong></li>
                        <li>Månadkostnad: <strong>{{ insurance.cost }}</strong></li>
                    </ul>
                    <p><a :href="insurance.prePurchaseInformation" target="_blank">Se förköpsinformation</a></p>
                </div>
                <h5>Vem gäller försäkringen för?</h5>
                <div class="requirements">
                    <ul>
                        <li>Du behöver vara fullt arbetsför och inte uppbära sjukpenning, närståendepenning eller liknande, samt vara inskriven och berättigad till A-kassa.</li>
                        <li>Du behöver vara tillsvidareanställd med en arbetstid om minst 22 timmar per vecka under minst 6 sammanhängande månader direkt före försäkringens tecknande.</li>
                        <li>Gäller enbart för dig som privatperson. Ej egenföretagare.</li>
                        <li>Du behöver vara folkbokförd och bosatt i Sverige.</li>
                        <li>Du ska ha fyllt 18 år men inte 60. Försäkringen gäller till och med att du blir 64 år.</li>
                    </ul>
                    <p><a :href="factSheet" target="_blank">Se fullständigt faktablad</a></p>
                </div>
                <div class="buttons-1">
                    <Button
                        :text="'Lägg till försäkring'"
                        size="lg"
                        background="purple"
                        @click="addInsuranceAndClose"
                    />
                </div>
                <p class="terms">
                    <strong>Du har 14 dagar ångerrätt</strong>
                </p>

                <p class="terms">
                    Genom att beställa Kortio Trygghetsförsäkring accepterar 
                    du våra <nuxt-link to="/anvandarvillkor/">villkor</nuxt-link> och 
                    <a :href="insuranceTerms" target="_blank">försäkringsvillkor</a>.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        const options = [
            {
                key: 'my-safety-3000',
                healthPayout: '3 000 kr/mån',
                unemployedPayout: '1 500 kr/mån',
                cost: '209 kr / mån',
                prePurchaseInformation: 'https://prismic-io.s3.amazonaws.com/kortio/8089e4b5-84bf-4a55-a6cf-74abab683036_Fo%CC%88rko%CC%88psinformation_Kortio+Trygghetsfo%CC%88rsa%CC%88kring_22_04+%283000%29.pdf'
            },
            {
                key: 'my-safety-5000',
                healthPayout: '5 000 kr/mån',
                unemployedPayout: '2 500 kr/mån',
                cost: '325 kr / mån',
                prePurchaseInformation: 'https://prismic-io.s3.amazonaws.com/kortio/d6f14192-eb7c-4416-9de1-ea76b69386a0_Fo%CC%88rko%CC%88psinformation_Kortio+Trygghetsfo%CC%88rsa%CC%88kring_22_04+%285000%29.pdf'
            },
            {
                key: 'my-safety-7000',
                healthPayout: '7 000 kr/mån',
                unemployedPayout: '3 500 kr/mån',
                cost: '449 kr / mån',
                prePurchaseInformation: 'https://prismic-io.s3.amazonaws.com/kortio/7de2c0cc-b475-46ce-a2ca-7d4a1f6c6c35_Fo%CC%88rko%CC%88psinformation_Kortio+Trygghetsfo%CC%88rsa%CC%88kring_22_04+%287000%29.pdf'
            }
        ];

        return {
            insuranceTerms: 'https://prismic-io.s3.amazonaws.com/kortio/9b5ffc16-f6e3-4993-934c-7d6b79e5a012_Villkor_Kortio+trygghetsfo%CC%88rsa%CC%88kring_22_04.pdf',
            factSheet: 'https://prismic-io.s3.amazonaws.com/kortio/9e6c68a6-c067-4218-b4b5-ddd37f4a6a3f_IPID_Kortio+Trygghetsfo%CC%88rsa%CC%88kring_22_04.pdf',
            readMore: false,
            options: options,
            insurance: options[0]
        };
    },
    mounted() {
        this.$event.insurance.formShown();
    },
    methods: {
        addInsuranceAndClose() {
            this.$emit('addInsurance', this.insurance.key);
            this.$event.insurance.added();
        },
        close() {
            this.$emit('close');
            this.$event.insurance.continued();
        }
    }
};
</script>

<style lang="scss" scoped>

    .information {
        @include border(top);
        margin-top: 20px;
        padding-top: 40px;
    }
    .rich-text ul {
        grid-gap: 0;
        gap: 0;
    }
    h5 {
        margin-top: 30px;
    }

    .cost {
        margin-top: 20px;
        // margin-bottom: -10px; // buttons-1 has 30 px and want it to be 20px
        padding: 20px;
        display: grid;
        background: $graybg;
        text-align: center;

        .amount {
            color: $green;
            font-size: 20px;
            font-weight: bold;
        }

        .header {
            font-size: 14px;
            margin-top: 10px;
        }
    }

    .requirements {
        background: $graybg;
        padding: 10px 20px;
        margin-top: 20px;
    }

    
</style>